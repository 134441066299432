import { useState } from 'react';
import '../App.css';
import { TailSpin } from "react-loader-spinner"
function UploadVideoComponent() {

  const [isLoading, setIsLoading] = useState(false)

  const onFormSubmit = async (e) => {
    e.preventDefault()
    const [firstName, detail, file] = e.target;
    const form = new FormData();

    if (!firstName.value || !detail.value || !file.files) {
      return alert('Debe completar todos los datos')
    }

    form.append('fileName', detail.value.toLowerCase());
    form.append('detail', firstName.value);
    form.append('file', file.files[0])
    setIsLoading(true)
    const result = await fetch("https://olivideos-azxtwoouiq-uc.a.run.app/videos", {
      method: "POST",
      body: form
    });

    setIsLoading(false)
    alert(result);

  }

  return (
    <>
      <div className="presentacion UploadVideoComponent">

        <div className="w-full h-screen flex justify-center gap-1 items-center flex-col ">
          <h1 className='font-bold title text-[#F3B0C3] md:text-[12rem] text-[6rem]'>Olivia </h1>
          <p className='text-[#140903] md:text-4xl text-2xl'> Subir nuevo video</p>
        </div>
        <div className="w-full h-screen flex justify-center gap-1 items-center flex-col ">
          <form className="w-full max-w-sm" onSubmit={onFormSubmit}>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                  Nombre de la persona
                </label>
              </div>
              <div className="md:w-2/3">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" name='detail' />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                  Descripcion de la persona
                </label>
              </div>
              <div className="md:w-2/3">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" name='detail' />
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="w-full">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="file" name="file" accept='video/*' />
              </div>
            </div>
            <TailSpin
              visible={isLoading}
              height="200"
              width="200"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <div className="md:flex md:items-center">
              <div className="w-full">
                <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                  Subir video
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>

  );
}

export default UploadVideoComponent;
