import '../../App.css';

export default function HeroComponent() {

    return (

        <div className='flex flex-col justify-around gap-y-40 soyyoymama'>
            <div id="soyyoymama" className="w-full flex justify-center gap-0 items-center flex-col h-screen md:h-auto">
                <img src="/images/2.png" alt="Mama y yo" className='w-full max-h-[90%] m-0 p-0' />
            </div>

            <div className="w-full flex flex-col md:flex-row h-screen md:h-auto *:gap-4 mt-20 md:mt-auto ">
                <img src="/images/3.png" alt="Con mama en la pile" className='w-full md:w-1/2 max-h-[90%] m-0 p-0' />
                <img src="/images/4.png" alt="Con mama en el columpio" className='w-full md:w-1/2 max-h-[90%] m-0 p-0' />
            </div>
            <div className="w-full flex  flex-col md:flex-row h-screen md:h-auto *:gap-4 mt-20 md:mt-12">

                <img src="/images/5.png" alt="Con mama y papa" className='w-full md:w-1/2 max-h-[90%] m-0 p-0' />
                <img src="/images/6.png" alt="Con mis hermanitos" className='w-full md:w-1/2 max-h-[90%] m-0 p-0' />
            </div>
            <div className="w-full  flex justify-center gap-0 items-center flex-col ">

                <a className='text-xl md:text-2xl hover:underline text-center' href='#videos'>Mi familia me dejo estos mensajitos</a>
            </div>
        </div>

    )
}