import '../../App.css';
import { useState, useEffect } from 'react';
import CardComponent from '../card';


export default function VideoComponent() {
    const [videoEvents, setVideovents] = useState([]);

    async function getVideos() {
      const data = await fetch("https://olivideos-azxtwoouiq-uc.a.run.app/videos")
      const json = await data.json()
      const photos = json.videos.map((video) => ({ src: video.url, detail: video.detail, id: video.id }))
      if (photos.length) {
        setVideovents(photos)
      }
    }
  
  
    useEffect(() => {
        getVideos().then(() => console.log("Get videos"))
    }, []);
  
  
    return (
        <div id="videos">
            <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
                <div className="grid grid-
    cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
                    {videoEvents.map((video) =>
                        <CardComponent imageSrc={video.src} description={video.detail} key={video.id} />
                    )}
                </div>
            </div>
        </div>
    )
}