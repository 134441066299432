import React, { useState, useEffect } from 'react';
function CardComponent({ imageSrc, description }) {
  return (
    <div className="rounded overflow-hidden shadow-lg h-max">

      <div className="relative w-auto">
          <video  controls preload='auto'>
            <source src={imageSrc} />
          </video>
          <p
          className="font-semibold text-lg inline-block absolute top-0 left-0 text-center bg-white w-full   text-[#F3B0C3] transition duration-500 ease-in-out">{description}</p>
      </div>

        
    </div>


  );
}

export default CardComponent;