import '../App.css';
import HeaderComponent from '../components/home/header';
import HeroComponent from '../components/home/hero';
import VideoComponent from '../components/home/video';

function App() {

  return (
    <div className='flex flex-col justify-around'>
      <HeaderComponent />
      <HeroComponent />
      <VideoComponent />
    </div>

  );
}

export default App;
