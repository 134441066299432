import '../../App.css';
export default function HeaderComponent() {
    return (
        <div className="presentacion App pb-12">
            <div className="w-full h-screen flex justify-center gap-5 items-center flex-col ">
                <h1 className='font-bold title text-[#F3B0C3] md:text-[12rem] text-[6rem]'>Olivia </h1>
                <p className='text-[#140903] md:text-4xl text-2xl'> Mi primera fiestita...</p>
                <a className='hover:underline cursor-pointer md:text-2xl text-xl' href='#soyyoymama'> Esta soy yo...</a>
            </div>
        </div>
    )
}